// extracted by mini-css-extract-plugin
export var marketplaceAppDevBenefitsSection = "P_hr";
export var marketplaceAppDevCaseSection = "P_hx";
export var marketplaceAppDevClientQuotes = "P_hz";
export var marketplaceAppDevIndustriesSection = "P_hq";
export var marketplaceAppDevOnlineSection = "P_hv";
export var marketplaceAppDevPracticesSection = "P_hw";
export var marketplaceAppDevPrimeSection = "P_hn";
export var marketplaceAppDevProcessSection = "P_hs";
export var marketplaceAppDevServicesSection = "P_hp";
export var marketplaceAppDevTechSection = "P_ht";
export var marketplaceAppExpertiseSection = "P_hy";